import React, { useMemo } from "react";
import { getSuggestions, Suggestion, SuggestionCategories, useCategorySuggestions } from 'ui'

const oneCategoryMax: number = 3;
const allCategoriesMax: number = 3 * oneCategoryMax;
const translation = (window as any).app.preloadState.translation;
const preloadState = (window as any).app.preloadState.headerSearchBox;

export default function App() {
    const { results, onFocus, onBlur, onInput, onKeyUp, value } = useCategorySuggestions();

    const visibleResults: SuggestionCategories = useMemo(() => {
        return getSuggestions(results, oneCategoryMax, allCategoriesMax);
    }, [results]);

    return (
        <>
            <input id="HeaderSearchBox" onKeyUp={onKeyUp} spellCheck="false" autoComplete="off" onInput={onInput} onFocus={onFocus} onBlur={onBlur} type="text" placeholder={translation["headerSearchBox.placeholder"]} />
            {(visibleResults.machineModels.length > 0 || visibleResults.usedParts.length > 0 || visibleResults.spareParts.length > 0) && <div className="headerSearchBoxResults">
                {visibleResults.spareParts.length > 0 && <>
                    <h5>{translation["headerSearchBox.spareParts"]} ({visibleResults.sparePartsTotal})</h5>
                    {visibleResults.spareParts.map(result => <Item key={result.itemNumber} suggestion={result} />)}
                </>}
                {visibleResults.usedParts.length > 0 && <>
                    <h5>{translation["headerSearchBox.usedParts"]} ({visibleResults.usedPartsTotal})</h5>
                    {visibleResults.usedParts.map(result => <Item key={result.itemNumber} suggestion={result} />)}
                </>}
                {visibleResults.machineModels.length > 0 && <>
                    <h5>{translation["headerSearchBox.machineModels"]} ({visibleResults.machineModelsTotal})</h5>
                    {visibleResults.machineModels.map(result => <Item key={result.itemNumber} suggestion={result} />)}
                </>}
                {visibleResults.sparePartsTotal + visibleResults.usedPartsTotal + visibleResults.machineModelsTotal >= allCategoriesMax &&
                    <a href={`${preloadState.searchResultPage}?query=${value}`}>
                        <span className="font-weight-bold text-center d-block">{translation["sparePartItem.showAllSpareParts"]}</span>
                    </a>
                }
            </div>}
        </>
  )
}

const Item = ({ suggestion }: { suggestion: Suggestion }) => {
    return <a key={suggestion.itemNumber} href={suggestion.url}>
        <span className="font-weight-bold">{suggestion.displayName}</span> -  <span className={"d-inline-block firstLetterCapital"}>{translation["sparePartItem.articleNo"]}: {suggestion.itemNumber}</span>
    </a>
}
