import { ReactNode, useState } from "react";
import { SuggestionCategories } from "..";

export const getSuggestions = (results: SuggestionCategories, oneCategoryMax: number, allCategoriesMax: number) => {
    const shownResults: SuggestionCategories = {
        ...results,
        spareParts: results.spareParts.slice(0, oneCategoryMax),
        usedParts: results.usedParts.slice(0, oneCategoryMax),
        machineModels: results.machineModels.slice(0, oneCategoryMax)
    };
    let sparePartsIndex = shownResults.spareParts.length;
    let usedPartsIndex = shownResults.usedParts.length;
    let machineModelsIndex = shownResults.machineModels.length;

    const hasEnoughItems = () => shownResults.spareParts.length + shownResults.usedParts.length + shownResults.machineModels.length >= allCategoriesMax;
    const hasSparePartsAvailable = () => sparePartsIndex < results.spareParts.length;
    const hasUsedPartsAvailable = () => usedPartsIndex < results.usedParts.length;
    const hasMachineModelsAvailable = () => machineModelsIndex < results.machineModels.length;

    while (!hasEnoughItems() && (hasSparePartsAvailable() || hasUsedPartsAvailable() || hasMachineModelsAvailable())) {
        if (hasSparePartsAvailable()) {
            shownResults.spareParts.push(results.spareParts[sparePartsIndex]);
            sparePartsIndex++;
            if (hasEnoughItems()) {
                break;
            }
        }
        if (hasUsedPartsAvailable()) {
            shownResults.usedParts.push(results.usedParts[usedPartsIndex]);
            usedPartsIndex++;
            if (hasEnoughItems()) {
                break;
            }
        }
        if (hasMachineModelsAvailable()) {
            shownResults.machineModels.push(results.machineModels[machineModelsIndex]);
            machineModelsIndex++;
            if (hasEnoughItems()) {
                break;
            }
        }
    }
    return shownResults;
}